import { PoweroffOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import logoIcon from 'assets/astrabiz-logo.png';
import userIcon from 'assets/avatar-cartoon.jpg';
import mobileLogo from 'assets/Favicon1.png';
import HomeIcon from 'assets/HomeIcon';
import TransIcon from 'assets/Transaction';
import AccountIcon from 'assets/Wallet';
import LogOutModal from 'components/LogOutModal';
import { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetAgentMutation } from 'services/agentServices/agent';

import 'styles/dashboard.css';

interface NavItemProps {
  to: string | string[];
  icon: React.ReactNode;
  label: string;
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

const NavItem: FC<NavItemProps> = ({ to, icon, label }) => {
  const { pathname } = useLocation();
  const isActive = Array.isArray(to) ? to.includes(pathname) : pathname === to;

  return (
    <>
      {isActive ? (
        <div className="das text-[#1174ED] font-[700] flex justify-between items-center w-[100%]">
          <div className="flex items-center gap-[10px]">
            {icon}
            <p className="hidden lg:block">{label}</p>
          </div>
          <div className="w-1 h-[36px] bg-[#3281FF] rounded-r-[25px]"></div>
        </div>
      ) : (
        <NavLink to={Array.isArray(to) ? to[0] : to}>
          <div className="flex items-center gap-[10px] cursor-pointer text-[#09090A] font-[400] text-[16px] leading-[22.4px] h-[36px]">
            {icon}
            <p className="hidden lg:block">{label}</p>
          </div>
        </NavLink>
      )}
    </>
  );
};

interface AgentSideBarProps {
  children: React.ReactNode;
}

const AgentSideBar: FC<AgentSideBarProps> = ({ children }) => {
  const [getAgent] = useGetAgentMutation();
  const [agentName, setAgentName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const response = await getAgent({});
        if (response?.data) {
          setAgentName(response.data.data.name);
        }
      } catch (error: unknown) {
        const err = error as ErrorResponse;
        if (
          err &&
          typeof err === 'object' &&
          'data' in err &&
          typeof err.data === 'object' &&
          'message' in err.data
        ) {
          notification.error({
            message: 'Error',
            description: err.data.message,
          });
        }
      }
    };
    fetchAgent();
  }, [getAgent]);

  const sidebarItems = useMemo(
    () => [
      { to: `/agent/dashbord`, icon: <HomeIcon />, label: 'Dashboard' },
      { to: `/agent/wallet`, icon: <AccountIcon />, label: 'Wallet' },
      { to: `/agent/transaction`, icon: <TransIcon />, label: 'Transactions' },
    ],

    []
  );

  useEffect(() => {
    const selectedTab = sidebarItems.find((item) =>
      Array.isArray(item.to)
        ? item.to.includes(window.location.pathname)
        : item.to === window.location.pathname
    );

    if (selectedTab) {
      document.title = `AstraBiz | ${selectedTab.label}`;
    }
  }, [sidebarItems]);

  return (
    <div className="flex">
      <div className="lg:w-[20%] w-[15%] h-[100vh] flex flex-col gap-[20px] md:gap-[40px] xl:gap-[50px] pt-[40px] items-center bg-[#F5F9FF] sticky top-0 sha">
        <div>
          <img src={logoIcon} alt={logoIcon} className="hidden md:block" />
          <img
            src={mobileLogo}
            alt={mobileLogo}
            className="md:hidden block h-[30px] w-[30px] relative right-2 bottom-3"
          />
        </div>
        <div className="flex flex-col gap-4 items-cente lg:items-start text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px] w-[100%] pl-[10px] md:pl-[45px]">
          {sidebarItems.map((item, index) => (
            <NavItem key={index} to={item.to} icon={item.icon} label={item.label} />
          ))}
        </div>
      </div>
      <div className="lg:w-[80%] w-[85%]">
        <div className="flex justify-end items-center bg-shadow bg-[#FFFFFF] py-[24px] px-[20px] md:px-[40px] h-[60px] md:h-[90px] xl:h-[100px] sticky top-0 z-10">
          <div className="flex items-center gap-0 xl:gap-2">
            <div className="w-[52.04px] border border-[#F0F0F0] rotate-[90deg]"></div>
            <div className="flex items-center gap-4">
              <img
                src={userIcon}
                alt={userIcon}
                className="border-[2px] border-[#1174ED] rounded-[50%] h-[40px] w-[40px] md:h-[54px] md:w-[54px]"
              />
              <h1 className="font-[600] text-[10px] md:text-[16px] leading-[19.36px]">
                {agentName}
              </h1>
              <button onClick={openModal}>
                <PoweroffOutlined className="text-[#1174ED]" />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[url('/src/assets/bg.svg')] bg-[#fff] bg-no-repeat bg-cover bg-center py-[18px] xl:py-[24px] px-[20px] lg:px-[40px]">
          {children}
        </div>
      </div>
      <LogOutModal isOpen={isModalOpen} handleCancel={closeModal} userType="agent" />
    </div>
  );
};

export default AgentSideBar;
